$font-family: "Nunito", sans-serif !important;
// $font-family2: "Playfair Display", serif;
$font-family2: "Nunito", sans-serif !important;
$main-color: #5172ff;
$main-color2: #6ba292;
$optional-color: #606060;
$white-color: #ffffff;
$black-color: #221638;
$font-size: 16px;
$transition: 0.5s;
$media: screen;
$feature_min: min-width;
$feature_max: max-width;
$value_one: 576px;
$value_two: 767px;
$value_three: 768px;
$value_four: 991px;
$value_five: 992px;
$value_six: 1199px;
$value_seven: 1200px;
$value_eight: 1355px;
$value_nine: 1550px;