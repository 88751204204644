@import "../../styles/scss/index.scss";

// $media: screen;
// $feature_min: min-width;
// $feature_max: max-width;
// $value_one: 576px;
// $value_two: 767px;
// $value_three: 768px;
// $value_four: 991px;
// $value_five: 992px;
// $value_six: 1199px;
// $value_seven: 1200px;
// $value_eight: 1355px;
// $value_nine: 1550px;

/* Max width 767px */
@media only #{$media} and ($feature_max : $value_two) {
  .banner-wrapper-area {
    padding-top: 100px !important;
  }

  .banner-wrapper-content {
    text-align: center !important;

    p {
      max-width: 100% !important;

      font: {
        weight: 500 !important;
        size: 15px !important;
      }
    }

    h1 {
      margin-bottom: 12px !important;
      font-size: 28px !important;
    }

    form {
      .input-search {
        font-size: 15px !important;
      }

      button {
        font-size: 14px !important;
        height: auto;
        border-radius: 5px !important;
        margin-top: 15px !important;
        position: relative !important;

        padding: {
          left: 25px !important;
          right: 25px !important;
          top: 10px !important;
          bottom: 10px !important;
        }
      }
    }

    .popular-search-list {
      margin-top: 17px !important;

      li {
        font-size: 14px !important;

        margin: {
          right: 10px !important;
          top: 3px !important;
        }
      }
    }
  }

  .banner-wrapper-image {
    margin-top: 30px !important;
  }

  .banner-shape8,
  .banner-shape9,
  .banner-shape10,
  .shape16,
  .shape2,
  .shape3,
  .shape4,
  .shape9 {
    display: none !important;
  }

  .banner-inner-area {
    .row {
      .col-lg-4 {
        &:last-child {
          .single-banner-box {
            border-bottom: none !important;
          }
        }
      }
    }
  }

  .single-banner-box {
    padding: 25px !important;
    text-align: center !important;
    border-radius: 0 !important;

    border: {
      right: none !important;
      bottom: 1px solid #eeeeee !important;
    }

    .icon {
      position: relative !important;
      left: 0 !important;
      top: 0 !important;
      transform: unset !important;
      font-size: 35px !important;
      margin-bottom: 15px !important;
    }

    h3 {
      margin-bottom: 8px !important;
      font-size: 18px !important;
    }
  }

  .courses-area {
    &.pt-100 {
      &.pb-70 {
        padding-bottom: 60px !important;

        &.bg-f5f7fa {
          padding-bottom: 30px !important;
        }
      }
    }
  }

  .section-title {
    max-width: 100% !important;

    margin: {
      bottom: 40px !important;
      left: 0 !important;
      right: 0 !important;
    }

    .sub-title {
      font-size: 12.5px !important;
    }

    h2 {
      max-width: 100% !important;
      font-size: 25px !important;

      margin: {
        left: 0 !important;
        right: 0 !important;
      }
    }

    p {
      max-width: 100% !important;

      margin: {
        left: 0 !important;
        right: 0 !important;
        top: 10px !important;
      }
    }
  }

  .single-courses-item {
    .courses-content {
      padding: 20px !important;

      .fav {
        font-size: 20px !important;
      }

      .price {
        font-size: 20px !important;
        margin-bottom: 6px !important;
      }

      h3 {
        font-size: 18px !important;
      }

      .courses-content-footer {
        padding-right: 0 !important;

        li {
          font-size: 14px !important;
        }
      }
    }
  }

  .single-courses-item {
    &.without-box-shadow {
      .courses-image {
        img {
          border-radius: 5px !important;
        }

        &::before {
          border-radius: 5px !important;
        }
      }
    }
  }

  .single-features-box {
    padding: 20px 15px !important;

    .icon {
      font-size: 40px !important;
    }

    h3 {
      margin-bottom: 12px !important;
      font-size: 18px !important;
    }

    .link-btn {
      margin-top: 3px !important;
      font-size: 14.5px !important;
    }

    &:hover {
      transform: translateY(0) !important;
    }

    &.without-padding {
      text-align: center !important;
    }
  }

  .about-area-two {
    &.pt-70 {
      padding-top: 60px !important;
    }
  }

  .about-content-box {
    padding-left: 0 !important;
    text-align: center !important;

    .sub-title {
      font-size: 12.5px !important;
    }

    h2 {
      font-size: 25px !important;
    }
  }

  .about-video-box {
    margin: {
      top: 30px !important;
      left: 0 !important;
    }

    .video-btn {
      width: 65px !important;
      height: 65px !important;
      font-size: 30px !important;
      line-height: 65px !important;
    }
  }

  .video-box {
    max-width: 100% !important;

    margin: {
      left: 0 !important;
      right: 0 !important;
      top: 60px !important;
    }

    .video-btn {
      width: 65px !important;
      height: 65px !important;
      font-size: 30px !important;
      line-height: 65px !important;
    }
  }

  .feedback-slides-two {
    &.owl-theme {
      .owl-dots {
        margin-top: 10px !important;
      }
    }
  }

  .single-feedback-box {
    padding: 25px !important;

    &::before {
      right: 20px !important;
      bottom: 50px !important;
      font-size: 70px !important;
    }
  }

  .divider2,
  .divider3 {
    display: none !important;
  }

  .single-categories-box {
    .content {
      h3 {
        font-size: 20px !important;
      }

      span {
        font-size: 14px !important;
      }
    }
  }

  .default-btn {
    font-size: 13px !important;
    padding-left: 20px !important;
    padding-right: 20px !important;
    padding-top: 10px !important;
    padding-bottom: 10px !important;

    i {
      left: 20px !important;
    }
  }

  .funfacts-list {
    .single-funfacts-box {
      margin: {
        bottom: 0 !important;
        top: 30px !important;
      }
    }
  }

  .single-funfacts-box {
    padding: {
      top: 40px !important;
      bottom: 40px !important;
      left: 20px !important;
      right: 20px !important;
    }

    h3 {
      font-size: 30px !important;

      span {
        font-size: 35px !important;
      }
    }

    p {
      font-size: 13px !important;
    }
  }

  .funfacts-area {
    .col-lg-3 {
      border: {
        right: none !important;
      }

      &:first-child {
        .single-funfacts-item {
          padding-top: 60px !important;
        }
      }

      &:last-child {
        .single-funfacts-item {
          border-bottom: none !important;
          padding-bottom: 60px !important;
        }
      }
    }
  }

  .single-funfacts-item {
    border-bottom: 1px solid #eaecf0 !important;

    padding: {
      top: 30px !important;
      bottom: 30px !important;
    }

    h3 {
      font-size: 30px !important;

      span {
        font-size: 35px !important;
      }
    }

    p {
      font-size: 13px !important;
    }
  }

  .funfacts-area-two {
    border: none !important;

    .col-lg-3 {
      border-right: none !important;

      &:first-child {
        .single-funfacts {
          padding-top: 0 !important;
        }
      }

      &:last-child {
        .single-funfacts {
          border-bottom: none !important;
          padding-bottom: 0 !important;
        }
      }
    }
  }

  .single-funfacts {
    border-bottom: 1px solid #eaecf0 !important;

    padding: {
      top: 30px !important;
      bottom: 30px !important;
    }

    img {
      display: none !important;
    }

    h3 {
      font-size: 30px !important;

      span {
        font-size: 35px !important;
      }
    }

    p {
      font-size: 13px !important;
    }
  }
}

/* Min width 768px Max width 991px */
@media only #{$media} and ($feature_min : $value_three) and ($feature_max : $value_four) {
  .banner-wrapper-area {
    padding-top: 100px;

    .divider {
      display: block;
    }
  }

  .banner-wrapper-content {
    text-align: center;

    h1 {
      margin-bottom: 18px;
      font-size: 32px;
    }

    p {
      font-size: 16px;
      max-width: 550px;

      margin: {
        left: auto;
        right: auto;
      }
    }

    form {
      max-width: 550px;

      margin: {
        left: auto;
        right: auto;
      }
    }
  }

  .banner-wrapper-image {
    margin-top: 30px;
  }

  .banner-shape8,
  .banner-shape9,
  .banner-shape10,
  .shape16,
  .shape4,
  .shape9 {
    display: none;
  }

  .banner-inner-area {
    .col-lg-4 {
      &:nth-child(2) {
        .single-banner-box {
          border-right: none;
        }
      }

      &:nth-child(3) {
        .single-banner-box {
          border-top: 1px solid #eeeeee;
        }
      }
    }
  }

  .single-banner-box {
    border-radius: 0 !important;
    padding: 25px 20px 25px 80px;

    h3 {
      font-size: 19px;
    }

    .icon {
      left: 20px;
      font-size: 40px;
    }
  }

  .courses-area {
    &.pt-100 {
      &.pb-70 {
        .courses-info {
          margin-bottom: 30px;
        }
      }
    }
  }

  .section-title {
    max-width: 650px;
    margin-bottom: 45px;

    .sub-title {
      font-size: 13.5px;
    }

    h2 {
      max-width: 520px;
      font-size: 30px;
    }

    p {
      max-width: 515px;
    }
  }

  .single-courses-item {
    .courses-content {
      .price {
        font-size: 24px;
      }

      h3 {
        font-size: 19px;
      }

      .courses-content-footer {
        padding-right: 70px;
      }
    }
  }

  .single-features-box {
    padding: 25px;

    h3 {
      margin-bottom: 13px;
      font-size: 19px;
    }

    .icon {
      font-size: 35px;
      margin-bottom: 17px;
    }

    .link-btn {
      font-size: 14px;
    }

    &.without-padding {
      text-align: center;
    }
  }

  .about-area-two {
    &.pt-70 {
      padding-top: 80px;
    }
  }

  .about-content-box {
    text-align: center;

    .sub-title {
      font-size: 13.5px;
    }

    h2 {
      font-size: 30px;
    }
  }

  .about-video-box {
    margin: {
      left: 0;
      top: 30px;
    }
  }

  .single-feedback-box {
    padding: 25px;
  }

  .divider2,
  .divider3 {
    display: none;
  }

  .single-categories-box {
    .content {
      h3 {
        font-size: 21px;
      }

      span {
        font-size: 14px;
      }
    }
  }

  .default-btn {
    font-size: 15px;

    i {
      top: 11px;
    }
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1355px) {
  .container {
    max-width: 1140px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .container {
    max-width: 720px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1355px) {
  .container {
    max-width: 1140px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .container {
    max-width: 720px;
  }
}

@media only #{$media} and ($feature_min : $value_one) and ($feature_max : $value_two) {
  .container {
    max-width: 540px;
  }

  .funfacts-area {
    .col-lg-3 {
      &:nth-child(2) {
        .single-funfacts-item {
          padding-top: 60px;
        }
      }

      &:nth-child(3) {
        .single-funfacts-item {
          border-bottom: none;
        }
      }
    }
  }

  .funfacts-area-two {
    .col-lg-3 {
      &:nth-child(2) {
        .single-funfacts {
          padding-top: 0;
        }
      }

      &:nth-child(3) {
        .single-funfacts {
          border-bottom: none;
        }
      }
    }
  }
}

/* Min width 992px Max width 1199px */
@media only #{$media} and ($feature_min : $value_five) and ($feature_max : $value_six) {
  .container {
    max-width: 960px;
  }

  .single-banner-box {
    padding: 30px 20px 30px 80px;

    .icon {
      left: 20px;
      font-size: 40px;
    }

    h3 {
      font-size: 19px;
    }
  }

  .section-title {
    h2 {
      font-size: 30px;
    }
  }

  .single-courses-item {
    .courses-content {
      .price {
        font-size: 20px;
      }

      h3 {
        font-size: 19px;
      }

      .courses-content-footer {
        padding-right: 0;
      }
    }
  }

  .shape16,
  .shape2,
  .shape3,
  .shape4,
  .shape9 {
    display: none;
  }

  .single-features-box {
    padding: 30px 15px;

    .icon {
      font-size: 40px;
    }

    h3 {
      font-size: 20px;
    }
  }

  .about-content-box {
    h2 {
      font-size: 30px;
    }
  }

  .single-categories-box {
    .content {
      h3 {
        font-size: 21px;
      }
    }
  }
}

@media only #{$media} and ($feature_min : $value_seven) and ($feature_max : $value_eight) {
  .about-area {
    .shape3 {
      display: none;
    }
  }
}

// MainBanner CSS
.container {
  max-width: 1230px;
  margin: 0 auto;
  padding: 0.8rem;
  padding-top: 0;
}

.banner-wrapper-area {
  position: relative;
  z-index: 1;
  background-color: #f8f9f8;

  padding: {
    top: 40px;
  }

  .divider {
    background-color: #f5f7fa;
    top: auto;
    bottom: 0;
  }
}

.banner-wrapper-content {
  h1 {
    margin-bottom: 18px;

    font: {
      size: 48px;
      weight: 800;
    }
  }

  p {
    font: {
      size: 17px;
    }
  }

  form {
    margin-top: 25px;
    position: relative;

    label {
      position: absolute;
      display: inline-block;
      margin-bottom: 0;
      left: 15px;
      top: 13px;
      color: $main-color;
      line-height: 1;
      font-size: 22px;
    }

    .input-search {
      display: block;
      width: 100%;
      height: 48px;
      border: 1px solid #eee8e2;
      border-radius: 5px;
      transition: $transition;
      color: $black-color;

      font: {
        size: $font-size;
        weight: 400;
      }

      padding: {
        left: 50px;
        top: 1px;
        bottom: 0;
        right: 0;
      }

      &::placeholder {
        color: #999999;
        transition: $transition;
      }

      &:focus {
        border-color: $main-color;

        &::placeholder {
          color: transparent;
        }
      }
    }

    button {
      position: absolute;
      right: 0;
      border-radius: 0 5px 5px 0;
      height: 48px;
      background-color: $main-color;
      color: $white-color;
      border: none;
      transition: $transition;

      padding: {
        top: 0;
        bottom: 0;
        left: 25px;
        right: 25px;
      }

      top: 0;

      font: {
        size: $font-size;
        weight: 600;
      }

      &:hover {
        background-color: $black-color;
        color: $white-color;
      }
    }
  }

  .popular-search-list {
    padding-left: 0;
    list-style-type: none;

    margin: {
      bottom: 0;
      top: 20px;
    }

    li {
      display: inline-block;
      margin-right: 12px;
      color: $optional-color;

      font: {
        size: 15px;
        weight: 600;
      }

      &:last-child {
        margin-right: 0;
      }

      a {
        display: block;
        color: $black-color;
        font-weight: 700;
        text-decoration: underline;

        &:hover {
          color: $main-color;
        }
      }
    }
  }
}

.banner-wrapper-image {
  text-align: center;
}

.banner-shape8 {
  position: absolute;
  z-index: -1;
  right: 5%;
  bottom: 7%;

  img {
    animation: {
      name: rotateme;
      duration: 50s;
      iteration-count: infinite;
      timing-function: linear;
    }
  }
}

.banner-shape9 {
  position: absolute;
  z-index: -1;
  top: 5%;
  right: 15%;

  img {
    animation: movescale 4s linear infinite;
  }
}

.banner-shape10 {
  position: absolute;
  z-index: -1;
  bottom: 30px;
  left: 35px;

  img {
    animation: movebounce 5s linear infinite;
  }
}

.banner-inner-area {
  margin-top: 50px;
  border-radius: 10px;

  .row {
    margin: {
      left: 0;
      right: 0;
    }

    .col-lg-4 {
      padding: {
        left: 0;
        right: 0;
      }

      &:last-child {
        .single-banner-box {
          border-right: none;
        }
      }

      &:nth-child(1) {
        .single-banner-box {
          border-radius: 10px 0 0 10px;
        }
      }

      &:nth-child(3) {
        .single-banner-box {
          border-radius: 0 10px 10px 0;
        }
      }
    }
  }
}

.single-banner-box {
  border-right: 1px solid #eeeeee;
  background-color: $white-color;
  padding: 30px 30px 30px 90px;
  position: relative;

  .icon {
    position: absolute;
    left: 30px;
    top: 50%;
    transform: translateY(-50%);
    line-height: 1;
    transition: $transition;
    font-size: 45px;

    i {
      &.flaticon-world {
        font-size: 40px;
      }
    }
  }

  h3 {
    margin-bottom: 5px;

    font: {
      size: 21px;
      weight: 700;
    }
  }

  &:hover {
    .icon {
      color: $main-color;
    }
  }
}

.form-btn {
  border-radius: 5px;
  height: 48px;
  background-color: #5172ff;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  transition: 0.5s;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  padding-left: 25px !important;
  padding-right: 25px !important;

  font-size: 16px;
  font-weight: 600;
}

/*================================================
Courses Area CSS
=================================================*/
.courses-area {
  position: relative;
  z-index: 1;
}

.courses-area {
  &.bg-image {
    background: {
      color: #fff7f4;
      image: url(/images/courses-bg.jpg);
      repeat: no-repeat;
      position: center center;
      size: cover;
    }
  }
}

/* section-title */
.section-title {
  text-align: center;
  max-width: 720px;

  margin: {
    left: auto;
    right: auto;
    bottom: 55px;
  }

  .sub-title {
    display: block;
    text-transform: uppercase;
    color: $main-color;
    margin-bottom: 10px;

    font: {
      size: 14px;
      weight: 600;
    }
  }

  h2 {
    max-width: 615px;

    margin: {
      bottom: 0;
      left: auto;
      right: auto;
    }

    font: {
      size: 36px;
      weight: 800;
    }
  }

  p {
    max-width: 615px;

    margin: {
      left: auto;
      right: auto;
      top: 12px;
    }
  }
}

.single-courses-item {
  margin-bottom: 30px;
  position: relative;
  border-radius: 5px;
  background-color: $white-color;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.02);

  .courses-image {
    display: block;
    border-radius: 5px;
    position: relative;
    z-index: 1;
    overflow: hidden;

    img {
      border-radius: 5px;
      width: 100%;
      transition: $transition;
    }

    &::before {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      border-radius: 5px;
      background-color: $black-color;
      opacity: 0.2;
      z-index: 1;
      transition: $transition;
    }

    .link-btn {
      position: absolute;
      left: 0;
      right: 0;
      border-radius: 5px;
      width: 100%;
      height: 100%;
      z-index: 2;
    }
  }

  .row {
    margin: {
      left: 0;
      right: 0;
    }

    .col-lg-4,
    .col-lg-8 {
      padding: {
        left: 0;
        right: 0;
      }
    }
  }

  .courses-content {
    border-radius: 5px;
    position: relative;
    padding: 25px;

    .fav {
      display: inline-block;
      position: absolute;
      right: 20px;
      top: 20px;
      color: #b0b9c4;
      font-size: 25px;
      line-height: 1;

      &:hover {
        color: $main-color;
      }
    }

    .price {
      display: block;
      color: $main-color;
      margin-bottom: 3px;

      font: {
        size: 28px;
        weight: 800;
      }
    }

    h3 {
      margin-bottom: 12px;
      line-height: 1.3;

      font: {
        size: 22px;
        weight: 800;
      }

      a {
        display: inline-block;
      }
    }

    .courses-content-footer {
      list-style-type: none;

      padding: {
        left: 0;
        right: 30px;
      }

      margin: {
        bottom: 0;
        left: -7px;
        right: -7px;
        top: 15px;
      }

      li {
        color: $optional-color;
        font-size: 15px;
        position: relative;

        padding: {
          left: 25px;
          right: 7px;
        }

        i {
          color: $main-color;
          position: absolute;
          left: 7px;
          top: 0;

          &.flaticon-people {
            font-size: 18px;
            left: 3px;
          }
        }
      }
    }
  }

  &:hover {
    .courses-image {
      &::before {
        opacity: 0;
        visibility: hidden;
      }

      img {
        transform: scale3d(1.1, 1.1, 1.1);
      }
    }
  }
}

.single-courses-item {
  &.without-box-shadow {
    box-shadow: unset;
    transition: $transition;

    .courses-image {
      img {
        border-radius: 50%;
      }

      &::before {
        border-radius: 50%;
      }
    }

    &:hover {
      box-shadow: 0px 8px 16px 0px rgba(146, 184, 255, 0.2);

      .courses-image {
        img {
          border-radius: 5px;
          transform: scale(1);
        }

        &::before {
          opacity: 0;
          visibility: hidden;
        }
      }
    }
  }
}

.shape16 {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  text-align: center;
}

/*================================================
Features Area CSS
=================================================*/
.features-area {
  position: relative;
  z-index: 1;
}

.single-features-box {
  text-align: center;
  margin-bottom: 30px;
  border-radius: 8px;
  background-color: #f9f9f9;
  padding: 30px 31px;
  transition: $transition;

  .icon {
    font-size: 50px;
    line-height: 1;
    margin-bottom: 15px;
  }

  h3 {
    margin-bottom: 15px;

    font: {
      size: 22px;
      weight: 800;
    }
  }

  p {
    margin-bottom: 12px;
  }

  .link-btn {
    display: inline-block;
    color: $main-color;

    font: {
      size: 15px;
      weight: 700;
    }

    &:hover {
      color: $black-color;
    }
  }

  &:hover {
    transform: translateY(-10px);
  }

  &.without-padding {
    text-align: left;
    padding: 0;
    background-color: transparent;

    // p {
    //     max-width: 315px;
    // }
  }
}

.about-area-two {
  position: relative;
  z-index: 1;

  .shape3 {
    right: 12%;
    bottom: auto;
    top: 25%;
  }

  .shape4 {
    right: 8%;
    bottom: auto;
    top: 7%;
  }

  .shape2 {
    left: auto;
    bottom: 5%;
    right: 15%;
  }
}

.about-content-box {
  .sub-title {
    display: block;
    text-transform: uppercase;
    color: $main-color;
    margin-bottom: 10px;

    font: {
      size: 14px;
      weight: 600;
    }
  }

  h2 {
    margin: {
      bottom: 16px;
    }

    font: {
      size: 36px;
      weight: 800;
    }
  }

  .link-btn {
    display: inline-block;
    color: $main-color;
    position: relative;
    border-bottom: 1px solid transparent;

    font: {
      weight: 700;
    }

    &:hover {
      color: $black-color;
      border-color: $black-color;
    }
  }

  .default-btn {
    margin-top: 10px;
  }
}

.about-video-box {
  margin-left: 4px;
  position: relative;
  z-index: 1;
  border-radius: 5px;
}

.about-video-box .image {
  overflow: hidden;
  border-radius: 5px;
  position: relative;
  z-index: 1;
}

.about-video-box .image::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  border-radius: 5px;
  background-color: #221638;
  opacity: 0.1;
  z-index: 1;
}

.about-video-box .image img {
  border-radius: 5px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.about-video-box .video-btn {
  display: inline-block;
  width: 80px;
  height: 80px;
  font-size: 35px;
  text-align: center;
  line-height: 80px;
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 1;
  -webkit-transform: translateY(-50%) translateX(-50%);
  transform: translateY(-50%) translateX(-50%);
  background-color: #5172ff;
  border-radius: 50%;
  color: #ffffff;
}

.about-video-box .video-btn::after,
.about-video-box .video-btn::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  z-index: -1;
  bottom: 0;
  left: 0;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  border-radius: 50%;
  border: 1px solid #5172ff;
}

.about-video-box .video-btn::before {
  -webkit-animation: ripple 2s linear infinite;
  animation: ripple 2s linear infinite;
}

.about-video-box .video-btn::after {
  -webkit-animation: ripple 2s linear 1s infinite;
  animation: ripple 2s linear 1s infinite;
}

.about-video-box .video-btn:hover {
  color: #ffffff;
  background-color: #221638;
}

.about-video-box .video-btn:hover::before,
.about-video-box .video-btn:hover::after {
  border-color: #221638;
}

.about-video-box:hover .image img {
  -webkit-transform: scale3d(1.1, 1.1, 1.1);
  transform: scale3d(1.1, 1.1, 1.1);
}

.about-video-box .shape10 {
  left: -6.5%;
  bottom: -13.5%;
  opacity: 0.5;
}

.shape2 {
  position: absolute;
  left: 5%;
  bottom: 8%;
  z-index: -1;
}

.shape2 img {
  -webkit-animation: movebounce 5s linear infinite;
  animation: movebounce 5s linear infinite;
}

.shape3 {
  position: absolute;
  right: 9%;
  bottom: 12%;
  z-index: -1;
}

.shape3 img {
  -webkit-animation-name: rotateme;
  animation-name: rotateme;
  -webkit-animation-duration: 100s;
  animation-duration: 100s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
}

.shape4 {
  position: absolute;
  right: 5%;
  bottom: 40%;
  z-index: -1;
}

.shape4 img {
  -webkit-animation: movescale 4s linear infinite;
  animation: movescale 4s linear infinite;
}

.shape10 {
  position: absolute;
  left: -11%;
  bottom: -11%;
  z-index: -1;
}

.shape10 img {
  border-radius: 0;
  -webkit-animation: moveleftbounce 4s linear infinite;
  animation: moveleftbounce 4s linear infinite;
}

// feedback slider

.feedback-area {
  position: relative;
  z-index: 1;

  .shape2 {
    bottom: 2%;
  }

  .shape9 {
    left: 8%;
    bottom: auto;
    top: 15%;
  }
}

.feedback-area {
  &.bg-6ba292 {

    .divider2,
    .divider3 {
      background-color: $main-color2;
    }

    .single-feedback-box {
      background-color: transparent;
      border-color: #8ab6a9;

      p {
        color: $white-color;
      }

      .client-info {
        .title {
          h3 {
            color: $white-color;
          }

          span {
            color: $white-color;
          }
        }
      }

      &::before {
        color: #909279;
      }
    }

    .feedback-slides-two {
      &.owl-theme {
        .owl-dots {
          .owl-dot {
            span {
              border-color: #8ab6a9;

              &::before {
                background-color: #8ab6a9;
              }
            }

            &:hover,
            &.active {
              span {
                border-color: $white-color;

                &::before {
                  background-color: $white-color;
                }
              }
            }
          }
        }
      }
    }
  }
}

.feedback-area {
  &.bg-6dbbbd {
    .section-title {
      .sub-title {
        color: $white-color;
      }

      h2 {
        color: $white-color;
      }

      p {
        color: $white-color;
      }
    }
  }
}

.feedback-slides-two {
  &.owl-theme {
    .owl-dots {
      margin-top: 20px !important;

      .owl-dot {
        span {
          margin: 0 3px;
          width: 15px;
          height: 15px;
          border-radius: 50%;
          background-color: transparent !important;
          border: 1px solid #a8a8a8;
          position: relative;
          transition: $transition;

          &::before {
            content: "";
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            background-color: transparent;
            border-radius: 50%;
            margin: 2px;
            transition: $transition;
          }
        }

        &:hover,
        &.active {
          span {
            border-color: $main-color;

            &::before {
              background-color: $main-color;
            }
          }
        }
      }
    }
  }
}

/* single feedback box */

.single-feedback-box {
  border: 1px solid #ffe8e3;
  position: relative;
  z-index: 1;
  padding: 40px;
  background-color: #fffaf3;
  margin-bottom: 30px;
  border-radius: 5px;
  overflow: hidden;
}

.single-feedback-box p {
  color: #606060;
  line-height: 1.8;
  font-size: 16px;
  font-weight: 600;
  margin-top: 0;
  margin-bottom: 0;
}

.single-feedback-box .client-info {
  margin-top: 25px;
}

.single-feedback-box .client-info img {
  width: 55px !important;
  height: 55px;
}

.single-feedback-box .client-info .title {
  margin-left: 15px;
}

.single-feedback-box .client-info .title h3 {
  margin-bottom: 0;
  color: #5172ff;
  font-size: 17px;
  font-weight: 700;
}

.single-feedback-box .client-info .title span {
  display: block;
  color: #606060;
  font-size: 14px;
  margin-top: 4px;
}

.single-feedback-box::before {
  content: "\f10a";
  position: absolute;
  right: 50px;
  color: #ebe6e0;
  z-index: -1;
  bottom: 25px;
  line-height: 1;
  font-family: Flaticon;
  font-size: 100px;
}

.feedback-area {
  position: relative;
  z-index: 1;

  .shape2 {
    bottom: 2%;
  }

  .shape9 {
    left: 8%;
    bottom: auto;
    top: 15%;
  }
}

.shape9 {
  position: absolute;
  left: 10%;
  bottom: 20%;
  z-index: -1;

  img {
    animation: {
      name: rotateme;
      duration: 10s;
      iteration-count: infinite;
      timing-function: linear;
    }
  }
}

/*================================================
Categories Area CSS
=================================================*/
.single-categories-box {
  margin-bottom: 30px;
  border-radius: 5px;
  position: relative;
  z-index: 1;
  overflow: hidden;
  text-align: center;

  img {
    border-radius: 5px;
    transition: $transition;
  }

  &::before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    border-radius: 5px;
    background-color: $black-color;
    opacity: 0.65;
    z-index: 1;
  }

  .content {
    position: absolute;
    left: 0;
    right: 0;
    height: auto;
    z-index: 2;
    top: 50%;
    transform: translateY(-50%);

    h3 {
      margin-bottom: 8px;
      color: $white-color;

      font: {
        size: 24px;
        weight: 800;
      }
    }

    span {
      color: $white-color;
      display: block;

      font: {
        size: 15px;
        weight: 600;
      }
    }
  }

  .link-btn {
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 5px;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 3;
  }

  &:hover {
    img {
      transform: scale3d(1.2, 1.2, 1.2);
    }
  }
}

.categories-btn-box {
  margin-top: 15px;
  text-align: center;
}

/* default-btn */
.default-btn {
  border: none;
  position: relative;
  display: inline-block;
  text-align: center;
  overflow: hidden;
  z-index: 1;
  color: $white-color;
  background-color: $main-color;
  transition: $transition;
  border-radius: 5px;

  font: {
    weight: 700;
    size: $font-size;
  }

  padding: {
    left: 30px;
    right: 30px;
    top: 11px;
    bottom: 11px;
  }

  span {
    position: absolute;
    display: block;
    width: 0;
    height: 0;
    border-radius: 50%;
    background-color: $black-color;
    transition: width 0.5s ease-in-out, height 0.5s ease-in-out;
    transform: translate(-50%, -50%);
    z-index: -1;
    border-radius: 5px;
  }

  i {
    position: absolute;
    left: 30px;
    top: 10px;
  }

  &:hover {
    color: $white-color;

    span {
      width: 225%;
      height: 562.5px;
    }
  }
}

/*================================================
Funfacts Area CSS
=================================================*/
.funfacts-and-feedback-area {
  position: relative;
  z-index: 1;
  background-image: url(/images/white-bg.jpg);
  background-position: bottom right;
  background-size: cover;
  background-repeat: no-repeat;
}

.funfacts-and-feedback-area .shape4 {
  right: 21%;
  bottom: 41%;
}

.funfacts-and-feedback-area .shape3 {
  right: 23%;
  bottom: 22%;
}

.funfacts-and-feedback-area .shape2 {
  left: auto;
  bottom: 3%;
  right: 25%;
}

.funfacts-list .single-funfacts-box {
  margin-bottom: 0;
}

.funfacts-list .row .col-lg-6:nth-child(1) .single-funfacts-box {
  margin-top: 60px;
  margin-bottom: 30px;
}

.funfacts-list .row .col-lg-6:nth-child(1) .single-funfacts-box::before {
  -webkit-animation-duration: 60s;
  animation-duration: 60s;
}

.funfacts-list .row .col-lg-6:nth-child(2) .single-funfacts-box {
  border-color: #5172ff;
}

.funfacts-list .row .col-lg-6:nth-child(2) .single-funfacts-box::before {
  -webkit-animation-duration: 70s;
  animation-duration: 70s;
}

.funfacts-list .row .col-lg-6:nth-child(3) .single-funfacts-box {
  border-color: #ad8360;
}

.funfacts-list .row .col-lg-6:nth-child(3) .single-funfacts-box::before {
  -webkit-animation-duration: 80s;
  animation-duration: 80s;
}

.funfacts-list .row .col-lg-6:nth-child(4) .single-funfacts-box {
  border-color: #221638;
  margin-top: -60px;
}

.funfacts-list .row .col-lg-6:nth-child(4) .single-funfacts-box::before {
  -webkit-animation-duration: 90s;
  animation-duration: 90s;
}

.single-funfacts-box {
  margin-bottom: 30px;
  background-color: #eee8e2;
  text-align: center;
  border: 1px dashed #308800;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  position: relative;
  overflow: hidden;
  z-index: 1;
  padding-top: 100px;
  padding-bottom: 100px;
  padding-left: 40px;
  padding-right: 40px;
}

.single-funfacts-box h3 {
  margin-bottom: 0;
  color: #5172ff;
  font-size: 44px;
  font-weight: 800;
}

.single-funfacts-box h3 span {
  font-size: 48px;
}

.single-funfacts-box h3 .odometer {
  position: relative;
  top: -2px;
}

.single-funfacts-box p {
  text-transform: uppercase;
  color: #221638;
  line-height: 1;
  margin-top: 10px;
  font-weight: 700;
  font-size: 15px;
}

.single-funfacts-box::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  margin: 10px;
  opacity: 0.7;
  z-index: -1;
  background-image: url(/images/funfacts-shape.png);
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
  -webkit-animation-name: rotateme;
  animation-name: rotateme;
  -webkit-animation-duration: 50s;
  animation-duration: 50s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
}

.single-funfacts-box:hover {
  border-color: #5172ff;
}

.single-funfacts-item {
  text-align: center;
  padding-top: 100px;
  padding-bottom: 100px;
}

.single-funfacts-item h3 {
  margin-bottom: 0;
  color: #5172ff;
  font-size: 44px;
  font-weight: 800;
}

.single-funfacts-item h3 span {
  font-size: 48px;
}

.single-funfacts-item h3 .odometer {
  position: relative;
  top: -2px;
}

.single-funfacts-item p {
  text-transform: uppercase;
  color: #221638;
  line-height: 1;
  margin-top: 10px;
  font-weight: 700;
  font-size: 16px;
}

.funfacts-area .col-lg-3 {
  border-right: 1px solid #eaecf0;
}

.funfacts-area .col-lg-3:last-child {
  border-right: none;
}

.funfacts-area-two {
  border-top: 1px solid #f4f3f5;
  border-bottom: 1px solid #f4f3f5;
}

.funfacts-area-two .col-lg-3 {
  border-right: 1px solid #f4f3f5;
}

.funfacts-area-two .col-lg-3:last-child {
  border-right: none;
}

.single-funfacts {
  text-align: center;
  position: relative;
  overflow: hidden;
  z-index: 1;
  padding-top: 100px;
  padding-bottom: 100px;
}

.single-funfacts img {
  position: absolute;
  left: 0;
  right: 0;
  opacity: 0.8;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: -1;
  margin-left: auto;
  margin-right: auto;
}

.single-funfacts h3 {
  margin-bottom: 0;
  color: #5172ff;
  font-size: 44px;
  font-weight: 800;
}

.single-funfacts h3 span {
  font-size: 48px;
}

.single-funfacts h3 .odometer {
  position: relative;
  top: -2px;
}

.single-funfacts p {
  text-transform: uppercase;
  color: #221638;
  line-height: 1;
  margin-top: 8px;
  font-weight: 700;
  font-size: 16px;
}

/* about video box */

// .about-video-box {
//   margin-left: 4px;
//   position: relative;
//   z-index: 1;
//   border-radius: 5px;
// }

// .about-video-box .image {
//   overflow: hidden;
//   border-radius: 5px;
//   position: relative;
//   z-index: 1;
// }

// .about-video-box .image::before {
//   content: "";
//   position: absolute;
//   left: 0;
//   right: 0;
//   top: 0;
//   bottom: 0;
//   border-radius: 5px;
//   background-color: #221638;
//   opacity: 0.1;
//   z-index: 1;
// }

// .about-video-box .image img {
//   border-radius: 5px;
//   -webkit-transition: 0.5s;
//   transition: 0.5s;
// }

// .about-video-box .video-btn {
//   display: inline-block;
//   width: 80px;
//   height: 80px;
//   font-size: 35px;
//   text-align: center;
//   line-height: 80px;
//   position: absolute;
//   left: 50%;
//   top: 50%;
//   z-index: 1;
//   -webkit-transform: translateY(-50%) translateX(-50%);
//   transform: translateY(-50%) translateX(-50%);
//   background-color: #5172ff;
//   border-radius: 50%;
//   color: #ffffff;
// }

// .about-video-box .video-btn::after,
// .about-video-box .video-btn::before {
//   content: "";
//   display: block;
//   position: absolute;
//   top: 0;
//   right: 0;
//   z-index: -1;
//   bottom: 0;
//   left: 0;
//   -webkit-transition: 0.5s;
//   transition: 0.5s;
//   border-radius: 50%;
//   border: 1px solid #5172ff;
// }

// .about-video-box .video-btn::before {
//   -webkit-animation: ripple 2s linear infinite;
//   animation: ripple 2s linear infinite;
// }

// .about-video-box .video-btn::after {
//   -webkit-animation: ripple 2s linear 1s infinite;
//   animation: ripple 2s linear 1s infinite;
// }

// .about-video-box .video-btn:hover {
//   color: #ffffff;
//   background-color: #221638;
// }

// .about-video-box .video-btn:hover::before,
// .about-video-box .video-btn:hover::after {
//   border-color: #221638;
// }

// .about-video-box:hover .image img {
//   -webkit-transform: scale3d(1.1, 1.1, 1.1);
//   transform: scale3d(1.1, 1.1, 1.1);
// }

// .about-video-box .shape10 {
//   left: -6.5%;
//   bottom: -13.5%;
//   opacity: 0.5;
// }

/*================================================
Video Area CSS
=================================================*/
.videoBox {
  position: relative;
  border-radius: 5px;
  max-width: 800px;
  z-index: 1;

  margin: {
    left: auto;
    right: auto;
    top: 100px;
  }

  .image {
    overflow: hidden;
    border-radius: 5px;

    img {
      border-radius: 5px;
      transition: $transition;
    }
  }

  .videoBtn {
    display: inline-block;
    width: 80px;
    height: 80px;
    font-size: 35px;
    text-align: center;
    line-height: 80px;
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: 1;
    transform: translateY(-50%) translateX(-50%);
    background-color: $main-color;
    border-radius: 50%;
    color: $white-color;

    &::after,
    &::before {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      z-index: -1;
      bottom: 0;
      left: 0;
      transition: $transition;
      border-radius: 50%;
      border: 1px solid $main-color;
    }

    &::before {
      animation: ripple 2s linear infinite;
    }

    &::after {
      animation: ripple 2s linear 1s infinite;
    }

    &:hover {
      color: $white-color;
      background-color: $black-color;

      &::before,
      &::after {
        border-color: $black-color;
      }
    }
  }

  &:hover {
    .image {
      img {
        transform: scale3d(1.1, 1.1, 1.1);
      }
    }
  }
}

@keyframes ripple {
  0% {
    transform: scale(1);
  }

  75% {
    transform: scale(1.75);
    opacity: 1;
  }

  100% {
    transform: scale(2);
    opacity: 0;
  }
}

.shape10 {
  position: absolute;
  left: -11%;
  bottom: -11%;
  z-index: -1;

  img {
    border-radius: 0;
    animation: moveleftbounce 4s linear infinite;
  }
}

.feedbackArea {
  position: relative;
  z-index: 1;
}

.clone {
  width: 100%;
  opacity: 0.05;
  position: absolute;
  margin-top: -60px;
  color: #333;
  text-transform: capitalize;
}